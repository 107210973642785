<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="3"
      >
        <statistics-card-with-images
          class="fill-height"
          :icon="ratingsOptions.icon"
          :avatar="ratingsOptions.avatar"
          :avatar-width="ratingsOptions.avatarWidth"
          :chip-color="ratingsOptions.chipColor"
          :chip-text="ratingsOptions.chipText"
          :statistics="totalAmountList"
          :stat-title="ratingsOptions.statTitle"
        ></statistics-card-with-images>
      </v-col>
      <v-col
        cols="12"
        sm="9"
      >
        <v-card class="fill-height">
          <v-card-title>Suchen</v-card-title>
          <v-card-text>
            <div class="d-flex align-center pb-5">
              <v-text-field
                v-model="filter.searchString"
                dense
                outlined
                hide-details
                placeholder="Event suchen"
                class="channel-list-search me-3"
              ></v-text-field>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card id="channel-list">
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <div class="d-flex align-center pb-5">
              <router-link :to="'/event/erstellen'" >
                <v-btn
                  color="secondary"
                  outlined
                  class="me-3"
                >
                  <v-icon size="30" class="pr-2">{{icons.mdiFire}}</v-icon>
                  <span>Event erstellen</span>
                </v-btn>
              </router-link>
            </div>
          </v-card-text>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="listEvents"
          :options.sync="options"
          :server-items-length="totalAmountList"
          :items-per-page="options.itemsPerPage"
          :loading="loading"
          :search="filter.searchString"
          class="text-no-wrap"
          no-data-text="Keine Einträge vorhanden"
          no-results-text="Ihre Suche ergab keinen Treffer"
          :footer-props="{
            itemsPerPageText: 'Anzahl pro Seite',
            itemsPerPageAllText: 'Alle',
            pageText: '{0}-{1} von {2}',
            itemsPerPageOptions: [5,10,25,50,-1]
          }"
        >
          <template
            v-slot:top
          >
            <v-dialog
              v-model="dialogDelete"
              width="500px"
            >
              <v-card>
                <v-card-text
                  class="text-center"
                >
                  Möchten Sie <strong>&nbsp;{{ deleteItem.titel }}&nbsp;</strong> wirklich löschen?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="deleteEventConfirm"
                  >
                    Löschen
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    @click="closeDelete"
                  >
                    Abbrechen
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogEdit"
              width="700px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="editItem.titel"
                          label="Titel"
                          dense
                          hide-details
                          outlined
                          :rules="[titleRules]"
                        ></v-text-field>
                      </v-col>
                      <v-col md="4">
                        <v-select
                          class="mb-3"
                          v-model="editItem.angabeAktiv"
                          hide-details="auto"
                          outlined
                          dense
                          :items="[{value:1,text:'Ja'},{value:0,text:'Nein'}]"
                          item-text="text"
                          item-value="value"
                          label="Aktiv"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="12">
                        <v-card outlined>
                          <v-card-title class="align-center">
                            <v-switch
                              v-model="showMemo"
                              inset
                              dense
                              hide-details
                            ></v-switch>
                            Memo
                          </v-card-title>
                          <v-card-text v-if="showMemo">
                            <v-textarea
                              v-model="editItem.memo"
                              label="Memo"
                              auto-grow
                              dense
                              hide-details
                              outlined
                              rows="6"
                              row-height="15"
                            ></v-textarea>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-radio-group
                          v-model="editItem.eventTyp"
                          column
                          class="mt-0"
                        >
                          <v-radio
                            value="http"
                          >
                            <template v-slot:label>
                              <v-chip
                                small
                                color="info"
                                class="v-chip-light-bg info--text font-weight-semibold"
                              >
                                HTTP
                              </v-chip>
                            </template>
                          </v-radio>
                          <v-radio
                            value="cpc"
                          >
                            <template v-slot:label>
                              <v-chip
                                small
                                color="primary"
                                class="v-chip-light-bg primary--text font-weight-semibold"
                              >
                                CPC-Protokollierung
                              </v-chip>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>

                    <v-row v-if="editItem.eventTyp == 'http'">
                      <v-col cols="12">
                        <v-text-field
                          v-model="editItem.url"
                          label="URL"
                          placeholder="https://"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row v-if="editItem.eventTyp == 'cpc'">
                      <v-col cols="12" md="4">
                        <v-select
                          class="mb-3"
                          v-model="editItem.waehrung"
                          hide-details="auto"
                          outlined
                          dense
                          :items="[{value:'EUR'}]"
                          item-text="value"
                          item-value="value"
                          label="Währung"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="editItem.cpc"
                          label="CPC"
                          placeholder="cpc"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    :disabled="editValidationError"
                    @click="editEventConfirm()"
                  >
                    Speichern
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    @click="closeEdit()"
                  >
                    Abbrechen
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

          </template>
          <template #[`item.idNipEvent`]="{item}">
            #{{ item.idNipEvent }}
          </template>
          <template #[`item.eventTyp`]="{item}">
            <v-chip
              small
              :color="item.eventTyp == 'http' ? 'info':'primary'"
              :class="`v-chip-light-bg ${item.eventTyp == 'http' ? 'info':'primary'}--text font-weight-semibold`"
            >
              {{ item.eventTyp }}
            </v-chip>
          </template>
          <template #[`item.angabeAktiv`]="{item}">
            <v-chip
              small
              @click="setEventState( item.idNipEvent, !item.angabeAktiv )"
              :color="item.angabeAktiv == 1 ? 'success':'secondary'"
              :class="`v-chip-light-bg ${item.angabeAktiv == 1 ? 'success':'secondary'}--text font-weight-semibold`"
            >
              {{ item.angabeAktiv == 1 ? 'Aktiv':'Inaktiv' }}
            </v-chip>
          </template>
          <template #[`item.actions`]="{item}">
            <div class="">
              <v-tooltip
                bottom
              >
                <template #activator="{ on, attrs }">
                  <router-link :to="'/event/editieren/'+item.idNipEvent" >
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiPencilOutline }}
                      </v-icon>
                    </v-btn>
                  </router-link>
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>

              <v-tooltip
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteEvent( item )"
                  >
                    <v-icon size="18">
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Löschen</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline, mdiFilePdf, mdiFire ,mdiPlus
} from '@mdi/js'
import axios from "axios";
import {getCurrentInstance, ref} from "@vue/composition-api";
import { avatarText } from '@core/utils/filter'
import InlineDatePicker from "@/views/InlineDatePicker.vue";
import router from '@/router'
import StatisticsCardWithImages from "@core/components/statistics-card/StatisticsCardWithImages";

export default {
  name: 'channel-list',
  components: {
    InlineDatePicker,
    StatisticsCardWithImages
  },
  setup() {
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    const ratingsOptions = {
      statTitle: 'Events',
      statistics: '13k',
      change: '+38%',
      chipText: 'Gesamt',
      chipColor: 'error',
      avatar: require('@/assets/images/avatars/9.png'),
      avatarWidth: '111',
      icon:mdiFire
    }

    return {
      vm,
      router,
      ratingsOptions
    }
  },
  data() {
    return {
      avatarText:avatarText,
      actions: ['Delete', 'Edit'],
      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiFire ,
        mdiFilePdf,
        mdiPlus,
      },
      filter: { searchString: '', status: 'offene',datumVon:'',datumBis:''},
      loading: false,
      totalAmountList: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['e.idNipEvent'],
        sortDesc: [true],
      },
      headers: [
        {
          text: '#',
          align: 'start',
          value: 'idNipEvent',
        },
        {text: 'Titel', value: 'titel'},
        {text: 'Event-Typ', value: 'eventTyp'},
        {text: 'Ausführungen', value: 'protocolCounter',align: 'right'},
        {text: 'Zeit Erstellung', value: 'zeitErstellungDE'},
        {text: 'Zeit Update', value: 'zeitUpdateDE'},
        {text: 'Status', value: 'angabeAktiv'},
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      listEvents: [],
      dialogDelete: false,
      dialogEdit: false,
      deleteItem: {
        idNipEvent: 0,
        name: '',
      },
      editIndex: -1,
      editValid : false,
      editItem: {
        idNipEvent: 0,
        angabeAktiv: 1,
        titel: '',
        memo: '',
        eventTyp: 'http',
        url: '',
        waehrung: 'EUR',
        cpc: 0.0,
      },
      defaultItem: {
        idNipEvent: 0,
        name: '',
        memo: '',
      },
      editValidationError : true,
      showMemo : false,

    }
  },
  mounted() {
    this.fetchEvents()
  },
  watch: {
    options: {
      handler() {
        this.fetchEvents()
      },
    },
    filter: {
      handler() {
        this.fetchEvents()
      },
      deep: true,
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogEdit (val) {
      val || this.closeEdit()
    },
  },
  computed: {
    formTitle () {
      return this.editIndex === -1 ? 'Event erstellen' : 'Event bearbeiten'
    },
  },
  methods: {
    fetchEvents() {
      this.loading = true
      axios.post('api/event/', {
        aktion: 'showOverviewEvents',
        filter: this.filter,
        options: encodeURIComponent(JSON.stringify(this.options)),
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.listEvents = response.data.events || []
            this.totalAmountList = parseInt(response.data.total) || 0
            this.loading = false
          } else {
            console.log(response.data.errors)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    setEventState(idNipEvent, state) {
      axios.post('api/event/', {
        aktion: 'changeEventState',
        idNipEvent: idNipEvent,
        newState: state,
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchEvents()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteEvent (item) {
      this.dialogDelete = true
      this.deleteItem = JSON.parse(JSON.stringify(item))
    },
    deleteEventConfirm () {
      axios.post('api/event/', {
        aktion: 'deleteEvent',
        mainData :{idNipEvent: this.deleteItem.idNipEvent},
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchEvents()
        })
        .catch((error) => {
          console.log(error)
        })

      this.closeDelete()
    },
    closeDelete () {
      this.dialogDelete = false
    },
    closeEdit () {
      this.$nextTick(() => {
        this.dialogEdit = false
        this.editIndex = -1
        this.editItem = this.defaultItem
      })
    },
    updateFilter (key,value) {
      this.$nextTick(() => {
        this.filter[key] = value;
      })
    },
    editEvent (item) {
      this.editValidationError = false
      this.dialogEdit = true
      if(item) {
        this.editIndex = this.listEvents.indexOf(item)
        this.editItem = Object.assign({}, item)


        this.showMemo = this.editItem.memo.length > 0
        console.log(this.editItem)
      }
    },
    titleRules (v) {
      this.editValidationError = true
      if(!v) {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      else if((v || '').length < 2) {
        return 'Der Titel muss mindetens 2 Zeichen besitzen.';
      }
      else if((v || '').length > 45) {
        return 'Der Titel kann maximal 45 Zeichen lang sein.';
      }

      this.editValidationError = false
      return true
    },

    editEventConfirm () {
      axios.post('api/event/', {
        aktion: this.editIndex === -1 ? 'createEventCheck' : 'editEventCheck',
        mainData: this.editItem,
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.vm.$toastr.s(response.data.message.title, response.data.message.text);
            this.fetchEvents()
          }
          else {
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              this.vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          this.closeEdit()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: normal;
}
#channel-list {
  .channel-list-search {
    max-width: 10.625rem;
  }
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
